<template>
  <div class="desktop_dropdown_products">
    <div class="img_section">
      <img src="../../assets/images/header/dropdown_img.png" />
    </div>
    <div class="right_box">
      <h2>Product overview</h2>
      <div class="prod_overview_items">
        <div class="img_box">
          <img alt="" src="../../assets/images/homePage/card1.svg">
        </div>
        <div>
          <h3 class="title">
            <button @click="openModal">Code</button>
          </h3>

          <p>Empower your coding journey with our zero-setup IDEs for 75+ languages</p>
        </div>
      </div>
      <div class="prod_overview_items">
        <div class="img_box">
          <img alt="" src="../../assets/images/homePage/card2.svg">
        </div>
        <div>
          <h3 class="title">
            <a href="">
              <router-link to="/compiler-api/">Integrate</router-link>
            </a>
          </h3>
          <p>Seamlessly connect IDEs to your applications, websites, and blogs with our powerful REST/WebSocket APIs and
            Plugins</p>
        </div>
      </div>
      <div class="prod_overview_items">
        <div class="img_box">
          <img alt="" src="../../assets/images/homePage/card3.svg">
        </div>
        <div>
          <h3 class="title">
            <router-link to="/guru">Teach and Assess</router-link>
          </h3>
          <p>Transform your classroom with our innovative teaching and assessment solutions</p>
        </div>
      </div>
      <div class="prod_overview_items">
        <div class="img_box">
          <img alt="" src="../../assets/images/homePage/card4.png">
        </div>
        <div>
          <h3 class="title">
            <router-link to="/education-institutions">Academia</router-link>
          </h3>
          <p>Integrate IDE into your LMS and platforms to enhance teaching and learning experiences</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  name: 'footer-block',
  methods: {
    openModal() {
      this.$emit('openCode')
    }
  }
}
</script>

<style lang="scss">
.desktop_dropdown_products {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  left: 0;
  width: 100%;
  padding: 80px 125px;

  h2 {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 73px;
    color: #000000;
  }

  .img_section {
    width: 50%;
  }

  .prod_overview_items {
    display: flex;
    padding-left: 15px;
    align-items: center;
    margin-bottom: 60px;

    .img_box {
      min-width: 80px;
    }

    img {
      padding-right: 25px;
    }

    .integrate_icon {
      width: 100px;

    }

    a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FD5A0D !important;
      margin-bottom: 10px;
    }

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FD5A0D !important;
      margin-bottom: 10px;
      border: unset;
      background: unset;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #000000;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

}

.right_box {
  width: 45%;
  padding-right: 90px;
}

@media only screen and (max-width: 1300px) {
  .right_box {
    padding-right: 0;
  }

}

@media only screen and (max-width: 990px) {
  .desktop_dropdown_products {
    padding: 60px 50px;
  }

  .desktop_dropdown_products {
    .prod_overview_items {
      .integrate_icon {
        width: 130px;
      }

      img {
        width: 90px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .img_section {
    display: none;
  }

  .right_box {
    width: 100%;
  }

}
</style>
