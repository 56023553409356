<template>
  <div id="app" class="main-page" v-bind:class="{ dark: isDark }">
    <Header :backgroundColor="navBarBackgroundColor"/>

    <div class="app-wrap">
      <router-view/>
    </div>
    <CookieLaw theme="blood-orange--rounded"></CookieLaw>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CookieLaw from 'vue-cookie-law'

export default {
  components: {
    Header,
    Footer,
    CookieLaw
  },
  data: () => {
    return {
      meta: {},
      navBarBackgroundColor: ''
    }
  },
  mounted () {
    let url = '/api/doodle/init'
    $.ajax({
      type: 'POST',
      url: url
    })
      .done(data => {
        $.ajaxSetup({
          headers: { 'Kurukku-Kuri': data.kurukkuKuri }
        })
        this.$store.commit('initApp')
        if (data.isUserLoggedIn === true) {
          this.$store.commit('login', data.username)
          this.$store.commit('notificationCount', data.notificationCount)

          if (
            data.isUserLoggedIn === true &&
            this.$router.currentRoute.name === 'login'
          ) {
            this.$router.push('/')
          }
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        window.jda.exception(url + ': ' + errorThrown)
      })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.meta = to.meta
    })
  },
  watch: {
    $route (to, from) {
      this.meta = to.meta
    },
    $route: {
      immediate: true,
      handler (to) {
        if (to.name === 'integrate') {
          this.navBarBackgroundColor = '#FFFFFF'
        } else {
          this.navBarBackgroundColor = '#f8f9f8'
        }
      }
    }
  },
  computed: {
    isDark () {
      return this.$store.state.dark && this.meta.language
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

#app {
  padding: 0.1em;
  position: relative;
  min-height: 100vh;
}

.main-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.app-wrap {
  flex: 1;
}

.dark {
  background-color: #171717;
  color: honeydew;
}

.captcha-box .grecaptcha-badge {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  visibility: hidden;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(../fonts/Inter-Medium.ttf) format("truetype");
}
</style>
