<template>
  <div class="footer">
    <div class="footer_logo">
      <img alt="footer_logo" src="../assets/images/logo_footer.png" />
    </div>
    <div class="info">
      <div class="menu_block">
        <div>
          <p>Products</p>
          <button @click="openModal('onlineIdea')">Online IDEs</button>
          <router-link to="/compiler-api">APIs and Plugin</router-link>
          <router-link to="/guru">Teach and Assess</router-link>
        </div>
        <div>
          <p>Docs</p>
          <a href="https://docs.jdoodle.com" target="_blank">Docs</a>
          <a href="/terms/" target="_blank">Privacy and Terms of Use</a>
        </div>
        <div>
          <p>Contact</p>
          <a href="mailto:hello@jdoodle.com" target="_blank">hello@jdoodle.com</a>
          <button @click="openModal('feedback')">Feedback</button>
          <div v-if="showModal" class="modal">
            <div class="modal-content">
              <!-- Modal content goes here -->
              <button class="close-btn" @click="closeModal">
                <img alt="" src="../assets/images/xModal.svg">
              </button>
              <div v-if="showModal === 'onlineIdea'" id="compilers_block" class="compilers_block">
                <div class="online-ideas-block">
                  <CompilersLeftBlock />
                  <div class="chose_language_block">
                    <CompilersRightBlock @openCommentBox="openModal('commentBox')" @closeCodeModal="closeModal" />
                  </div>
                </div>
              </div>
              <div class="leave_comment">
                <CommentsBox v-if="showModal === 'commentBox'" />
              </div>
              <div v-if="showModal === 'feedback'">
                <div class="feedback-modal">
                  <CommentsBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="social_networks_block">
        <div>
          <p>© 2013-2023 Nutpan pty Ltd. All Rights Reserved</p>
          <p>
            JDoodle uses cookies. Please read our <a href="/terms/" target="_blank">Terms of Use</a> before using
            this
            service
          </p>
        </div>
        <div class="social_networks">
          <a href="mailto:hello@jdoodle.com" target="_blank">
            <img alt="mail" src="../assets/images/mail.svg" />
          </a>
          <a href="https://www.facebook.com/jdoodleide" target="_blank">
            <img alt="facebook" src="../assets/images/facebook.svg" />
          </a>
          <a href="https://twitter.com/jdoodleide" target="_blank">
            <img alt="twitter" src="../assets/images/twitter.svg" />
          </a>
          <a href="https://www.linkedin.com/company/jdoodle" target="_blank">
            <img alt="linkedin" src="../assets/images/linkedin.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompilersLeftBlock from '../components/HomePage/CompilersLeftBlock.vue'
import CompilersRightBlock from '../components/HomePage/CompilersRightBlock.vue'
import CommentsBox from './Utils/CommentsBox'

export default {
  components: {
    CompilersLeftBlock,
    CompilersRightBlock,
    CommentsBox
  },
  data() {
    return {
      showModal: false,
      opened: false
    }
  },
  name: 'footer-block',
  methods: {
    openModal(value) {
      this.showModal = value
    },
    closeModal() {
      this.showModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  padding: 2% 12% 8% 10%;
  background: #f8f9f8;
  display: flex;


  .footer_logo {
    width: 20%;
    text-align: center;
  }

  .info {
    margin-left: 10%;
    width: 70%;

    .menu_block {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 20px;
        }

        a {
          text-decoration: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
          margin-top: 12px;
        }

        button {
          border: unset;
          background: unset;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
          margin-top: 12px;
          display: flex;
          cursor: pointer;
        }

        span {
          font-family: "Inter";
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
        }
      }
    }

    .social_networks_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 11%;
      gap: 10px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }

      a {
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }

      .social_networks {
        display: flex;
        gap: 12px;

        a {
          display: flex;
        }
      }
    }
  }

}

/* scroll styles */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

@media only screen and (max-width: 1040px) {
  .footer {
    padding: 8% 8% 6% 6%;

    .footer_logo {
      width: 17%;
    }

    .info {
      width: 75%;

      .menu_block {
        div {
          p {
            font-size: 17px;
            margin-bottom: 18px;
          }

          a {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .comment-box-title[data-v-5a339239] {
    margin-top: 50px;
  }

  .leave_comment {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 880px) {
  .footer {
    justify-content: space-between;

    .info {
      margin-left: 5%;

      .menu_block {
        div {
          p {
            font-size: 16px;
            margin-bottom: 12px;
          }

          a {
            font-size: 13px;
            margin-top: 8px;
          }
        }
      }

      .social_networks_block {
        p {
          font-size: 11px;
        }

        .social_networks {
          gap: 10px;
        }
      }
    }
  }

}

@media only screen and (max-width: 630px) {
  .footer {
    flex-direction: column;

    .footer_logo {
      width: 120px;
      display: flex;
    }

    .info {
      margin-left: 0px;
      margin-top: 30px;
      width: 100%;

      .menu_block {
        gap: 15px;

        div {
          p {
            font-size: 15px;
            margin-bottom: 10px;
          }

          a {
            font-size: 12px;
            margin-top: 5px;
          }

          button {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }

      .social_networks_block {
        p {
          font-size: 10px;
        }

        .social_networks {
          gap: 7px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .footer {
    padding: 30px 22px;
    flex-direction: column;

    .footer_logo {
      width: 140px;
    }

    .info {
      margin-top: 40px;

      .menu_block {
        flex-direction: column;
        gap: 0px;

        div {
          margin-bottom: 48px;

          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }

          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }

          button {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 5px;
          }
        }
      }

      .social_networks_block {
        flex-direction: column-reverse;
        align-items: start;
        margin-top: 0px;
        gap: 30px;

        p {
          font-size: 12px;
          line-height: 15px;
          margin-top: 10px;
        }

        .social_networks {
          gap: 12px;
        }
      }
    }
  }

}
</style>
