<template>
  <div :style="{ backgroundColor: backgroundColor }" class="nav_bar">
    <router-link to="/">
      <div class="logo">
        <img alt="logo" src="../assets/images/header/Logo.png" />
      </div>
    </router-link>
    <!-- Modal -->
    <div v-if="showModal" class="modal modalOpen">
      <div class="modal-content">
        <!-- Modal content goes here -->
        <button class="close-btn" @click="closeModal">
          <img alt="" src="../assets/images/xModal.svg">
        </button>
        <div v-if="showModal === 'onlineIdea'" id="compilers_block" class="compilers_block">
          <div class="online-ideas-block">
            <CompilersLeftBlock />
            <div class="chose_language_block">
              <CompilersRightBlock @openCommentBox="openModal('commentBox')" @closeCodeModal="closeModal" />
            </div>
          </div>
        </div>

        <div class="leave_comment">
          <CommentsBox v-if="showModal === 'commentBox'" />
        </div>
      </div>
    </div>
    <div class="right_section">
      <div class="desktop_product">
        <div class="desktop_product_title" @click="desktopProductDrop">
          <a>Products</a>
          <img v-if="!desktopProductDropDown" class="arrow_icon" src="../assets/images/header/dropDown.svg" />
          <img v-if="desktopProductDropDown" class="arrow_icon" src="../assets/images/header/dropDownUp.svg" />
        </div>

        <div v-if="desktopProductDropDown" class="" @click="desktopProductDrop">
          <ProductOverview @openCode="openModal('onlineIdea')"></ProductOverview>
        </div>

      </div>
      <a href="https://docs.jdoodle.com" target="_blank">Docs</a>
      <div v-if="!$store.state.isUserLoggedIn" @click="currentSideBarComponent = 'Login'">
        <MyButton>Log In</MyButton>
      </div>
      <button v-if="$store.state.isUserLoggedIn" class="button user-button is-rounded"
        @click="currentSideBarComponent = 'UserMenu'">
        <font-awesome-icon class="has-text-dangershare-button-icon has-text-black" icon="user" />
      </button>

    </div>
    <div class="burger" @click="showMenu">
      <button v-if="$store.state.isUserLoggedIn" class="button user-button is-rounded"
        @click="currentSideBarComponent = 'UserMenu'">
        <font-awesome-icon class="has-text-dangershare-button-icon has-text-black" icon="user" />
      </button>
      <img v-if="!showMobileMenu" src="../assets/images/header/burger.svg" />
      <img v-if="showMobileMenu" src="../assets/images/header/x.svg" />
    </div>
    <div v-if="showMobileMenu" :style="{ backgroundColor: backgroundColor }" class="mobile_menu">
      <div class="mobile_product">
        <div class="product_title" @click="productDrop">
          <a>Products</a>
          <img v-if="!productDropDown" src="../assets/images/header/dropDown.svg" />
          <img v-if="productDropDown" src="../assets/images/header/dropDownUp.svg" />
        </div>

        <div v-if="productDropDown" class="dropdown_products" @click="showMenu">
          <button @click="openModal('onlineIdea')">Code</button>
          <router-link to="/compiler-api/">Integrate</router-link>
          <router-link to="/guru">Teach and Assess</router-link>
        </div>
      </div>
      <div class="menu_links" @click="showMenu">
        <router-link to="/">Case Studies</router-link>
        <a href="https://docs.jdoodle.com" target="_blank">Docs</a>

        <a v-if="!$store.state.isUserLoggedIn" @click.stop="currentSideBarComponent = 'Login'">Log In</a>
      </div>
    </div>
    <component v-bind:is="currentSideBarComponent" v-bind="propForSideBar" @changeComponent="changeComponent"
      @changePage="changePage" @toggle="currentSideBarComponent = null"></component>
  </div>
</template>
<script>
import MyButton from './MyButton.vue'
import AuthModal from './AuthModal.vue'
import { eventBus } from '../assets/javascript/event-bus'
import { GURU_EVENTS } from '../assets/javascript/constants'
import ProductOverview from './HomePage/ProductOverview'
import CompilersLeftBlock from '../components/HomePage/CompilersLeftBlock.vue'
import CompilersRightBlock from '../components/HomePage/CompilersRightBlock.vue'
import CommentsBox from './Utils/CommentsBox'

export default {
  name: 'header-app',
  components: {
    ProductOverview,
    UserMenu: () => import('./UserMenu'),
    MainMenu: () => import('./MainMenu'),
    Login: () => import('./Login'),
    Register: () => import('./Register'),
    ForgotPassword: () => import('./ForgotPassword'),
    ChangePassword: () => import('./ChangePassword'),
    ChangeDisplayName: () => import('./ChangeDisplayName'),
    /* DeleteAccount: () => import('./DeleteAccount'), */
    UnlockAccount: () => import('./UnlockAccount'),
    SideBar: () => import('./Utils/SideBar'),
    SocialIcons: () => import('./SocialIcons'),
    MyButton,
    AuthModal,
    CompilersLeftBlock,
    CompilersRightBlock,
    CommentsBox
  },
  props: {
    backgroundColor: {
      type: String
    }
  },
  data() {
    return {
      showMobileMenu: false,
      currentSideBarComponent: null,
      propForSideBar: null,
      productDropDown: false,
      desktopProductDropDown: false,
      showModal: false
    }
  },
  methods: {
    openModal(value) {
      this.showModal = value
    },
    closeModal() {
      this.showModal = false
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu
      document.querySelector('html').style.overflow = this.showMobileMenu
        ? 'hidden'
        : 'auto'
    },
    productDrop() {
      this.productDropDown = !this.productDropDown
    },
    desktopProductDrop() {
      this.desktopProductDropDown = !this.desktopProductDropDown
      if (this.desktopProductDropDown) {
        document.addEventListener('click', this.closeDesktopProductMenu);
      } else {
        document.removeEventListener('click', this.closeDesktopProductMenu);
      }
    },
    closeDesktopProductMenu(event) {
      const menuElement = this.$el.querySelector('.desktop_product');
      if (!menuElement.contains(event.target)) {
        this.desktopProductDropDown = false;
        document.removeEventListener('click', this.closeDesktopProductMenu);
      }
    },
    changeComponent(newComponent) {
      this.currentSideBarComponent = newComponent
    },
    changePage(newPage) {
      this.$router.push(newPage)
      this.currentSideBarComponent = null
    }
  },
  mounted() {
    eventBus.$on(GURU_EVENTS.SHOW_IN_SIDEBAR, (component, properties) => {
      this.currentSideBarComponent = component
      this.propForSideBar = properties
    })
  }
}
</script>
<style lang="scss">
.modalOpen {
  display: block;
}

.nav_bar {
  position: relative;
  padding: 35px 12% 35px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right_section {
    display: flex;
    align-items: center;
    gap: 43px;

    .desktop_product {
      .desktop_product_title {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #363739;
      text-decoration: none;
    }
  }

  .burger {
    display: none;
  }

  .mobile_menu {
    position: absolute;
    display: none;
    flex-direction: column;
    top: 60px;
    left: 0px;
    right: 0px;
    padding: 0px 40px;
    margin-top: 40px;
    background-color: #f8f9f8;
    height: 100vh;
    z-index: 999;

    .menu_links {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 30px;
    }

    a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
      text-decoration: none;
    }

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      background: unset;
      border: unset;
      display: flex;
    }

    .mobile_product {
      display: inline-block;

      .product_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .dropdown_products {
        display: flex;
        flex-direction: column;
        gap: 31px;
        margin-top: 31px;
        padding-left: 22px;
      }
    }
  }

  .arrow_icon {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 1040px) {
  .nav_bar {
    padding: 35px 8% 35px 6%;

    .right_section {
      gap: 30px;

      a {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .nav_bar {
    .right_section {
      gap: 14px;

      a {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .nav_bar {
    .right_section {
      display: none;
    }

    .burger {
      display: block;
    }

    .mobile_menu {
      display: flex;
    }

    .is-rounded {
      background: transparent;
      border: transparent;
    }
  }
}

@media only screen and (max-width: 530px) {
  .nav_bar {
    background: #f8f9f8 !important;

    .mobile_menu {
      background: #f8f9f8 !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .nav_bar {
    padding: 15px 20px;

    .logo {
      width: 116px;

      img {
        width: 100%;
      }
    }

    .mobile_menu {
      margin-top: 12px;
    }
  }
}
</style>
