<template>
  <div class="modal">
    <div class="header_modal">
      <div class="logo_modal" @click="$emit('close')">
        <img src="../assets/images/header/Logo.png" alt="logo" />
      </div>
      <div @click="$emit('close')">
        <img src="../assets/images/header/xModal.svg" />
      </div>
    </div>
    <div class="block_modal">
      <p class="block_modal_title" v-if="!signUp">Sign in to JDoodle</p>
      <p class="block_modal_title" v-if="signUp">Sign Up to JDoodle</p>
      <div class="recommended">
        <p>We recommended using</p>
        <div class="google_or_mic">
          <div class="google" @click="googleSignIn">
            <img src="../assets/images/header/google.svg" />
            <p>Sign in with Google</p>
          </div>
          <div class="mic" @click="microsoftSignIn">
            <img src="../assets/images/header/microsoft.svg" />
            <p>Sign in with Microsoft</p>
          </div>
        </div>
      </div>
      <div class="or">
        <span></span>
        <p>or</p>
        <span></span>
      </div>
      <div class="email">
        <div>
          <p>Email</p>
          <div v-if="!signUp">
            <p>New to JDoodle?</p>
            <span @click="changeValue">Sign up</span>
          </div>

          <div v-if="signUp">
            <p>Already have an account?</p>
            <span @click="changeValue"> Sign in</span>
          </div>
        </div>
        <input
          type="text"
          v-model="payload.email"
          @input="$v.payload.email.$touch()"
          :class="{ error_input: $v.payload.email.$error }"
        />
        <div
          class="error"
          v-if="$v.payload.email.$error && !$v.payload.email.required"
        >
          Field is required.
        </div>
        <div
          class="error"
          v-else-if="$v.payload.email.$error && !$v.payload.email.minLength"
        >
          Field must have at least
          {{ $v.payload.password.$params.minLength.min }} characters.
        </div>
        <div
          class="error"
          v-else-if="$v.payload.email.$error && !$v.payload.email.email"
        >
          example-jdoodle@gmail.com
        </div>
      </div>
      <div class="password">
        <div>
          <p v-if="!signUp">Password</p>
          <p v-if="signUp">New Password</p>

          <span v-if="!signUp">Forget your password?</span>
        </div>
        <input
          type="text"
          @input="$v.payload.password.$touch()"
          v-model="payload.password"
          :class="{ error_input: $v.payload.password.$error }"
        />
        <div
          class="error"
          v-if="$v.payload.password.$error && !$v.payload.password.required"
        >
          Field is required.
        </div>
        <div
          class="error"
          v-else-if="
            $v.payload.password.$error && !$v.payload.password.minLength
          "
        >
          Field must have at least
          {{ $v.payload.password.$params.minLength.min }} characters.
        </div>
      </div>
      <button class="sign_in_button" v-if="!signUp">Sign In</button>
      <button class="sign_up_button" v-if="signUp">Sign Up</button>
    </div>
  </div>
</template>
<script>
import formMixin from '../assets/javascript/form-mixin'
import oauthMixin from '../assets/javascript/oauth-mixin'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  name: 'auth_modal',
  mixins: [formMixin, oauthMixin, recaptchaMixin],
  data () {
    return {
      signUp: false,
      payload: {
        email: '',
        password: '',
        newPassword: ''
      }
    }
  },
  validations: {
    payload: {
      email: {
        required,
        email,
        minLength: minLength(4)
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    changeValue () {
      this.signUp = !this.signUp
      this.payload.email = ''
      this.payload.password = ''
      this.payload.newPassword = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 40px 100px 0px 107px;
  background-color: #ffffff;
  height: 100vh;
  z-index: 999;
  .error {
    color: red;
    margin-top: 5px;
  }

  .header_modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .block_modal {
    margin: auto;
    width: 421px;
    .block_modal_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
    .recommended {
      margin-top: 24px;
      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
      .google_or_mic {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          padding: 15px 20px;
          align-items: center;
          border: 1px solid #30475e;
          border-radius: 5px;
          width: 48%;
          cursor: pointer;
          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            margin-left: 10px;
          }
        }
      }
    }
    .or {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 36px;
      span {
        width: 100%;
        border: 1px solid #ebebeb;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }
    .email {
      margin-top: 24px;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        div {
          display: flex;
          align-items: center;
          span {
            color: #2477ff;
            margin-left: 3px;
            cursor: pointer;
          }
        }
      }
      input {
        border: 1px solid #ebebeb;
        border-radius: 5px;
        width: 100%;
        padding-left: 4%;
        height: 50px;
        margin-top: 10px;
        outline: none;
      }
      .error_input {
        border: 2px solid red;
      }
    }
    .password {
      margin-top: 24px;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        span {
          color: #2477ff;
          margin-left: 3px;
          cursor: pointer;
        }
      }
      input {
        border: 1px solid #ebebeb;
        border-radius: 5px;
        width: 100%;
        padding-left: 4%;
        height: 50px;
        margin-top: 10px;
        outline: none;
      }
      .error_input {
        border: 2px solid red;
      }
    }
    .sign_up_button,
    .sign_in_button {
      padding: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      background: #363739;
      border-radius: 5px;
      width: 100%;
      border: none;
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 769px) {
  .modal {
    padding: 40px 30px 0px 30px;
  }
}
@media only screen and (max-width: 550px) {
  .modal {
    padding: 40px 15px 0px 15px;
    .header_modal {
      padding-left: 17px;
      padding-right: 17px;
    }
    .block_modal {
      width: 100%;
      margin-top: 74px;
      .recommended {
        text-align: center;
        .google_or_mic {
          flex-direction: column;
          align-items: center;
          gap: 19px;
          div {
            width: 200px;
            justify-content: center;
          }
        }
      }
      .password,
      .email {
        input {
          width: 96%;
        }
      }
    }
  }
}
</style>
